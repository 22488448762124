// @ts-nocheck
import React, { Component } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

export type withLoaderProps = {
  displaySpinner : () => void,
  hideLoader : () => void,
  showLoader : () => void,
}

function WithLoader(OriginalComponent) {
  return class extends Component {
    state = {
      isLoading: false,
    };
    showLoader = ()=>{
      this.setState({
        isLoading : true,
      })
    }
    displaySpinner = ()=>{
      this.setState({
        isLoading : true,
      })
    }
    
    hideLoader = () => {
      this.setState({ isLoading: false });
    };

    render() {
      return (
        <>
          {this.state.isLoading && (
            <Backdrop style={{zIndex : 9999}} open={this.state.isLoading}>
              <div
                style={{
                  position : 'absolute',
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#602961",
                }}
              >
                  <CircularProgress size="5rem" color="#602961" />
              </div>
              </Backdrop>
          )}
          <OriginalComponent
            {...this.props}
            showLoader={this.showLoader}
            displaySpinner={this.displaySpinner}
            hideLoader={this.hideLoader}
          />
        </>
      );
    }
  };
}
export default WithLoader;
