Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OfflineBrowsing";
exports.labelBodyText = "OfflineBrowsing Body";
exports.downloadText = "Download";
exports.downloadDes = "All your downloaded videos will appear here.";
exports.deleteText = "Delete";
exports.noVideoTest = "No downloaded videos available.";
exports.RecommendedVideos = "Recommended Videos";
exports.deleteVideo = "Are you sure you want to delete this video?";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End