import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface ApiData {
  contentType: string; 
  method: string; 
  endPoint: string; 
  body?: Body; 
  type?: string;
}
interface Body {
  [key: string] : string | number[]
}
export interface QueryParams {
  [key: string]: string | string[]; // Allow both string values and string arrays
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedFilter: any;
  selectedFilterOptions: string[]
  appliedFilters: any;
  filterList: any;
  recentList: any;
  isOpen: boolean;
  isLoading: boolean;
  pagination: any;
  currentPage: number;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class FilteroptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFilterApiCall: any;
  getRecentalyUploaded: any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedFilter: configJSON.filters[0],
      selectedFilterOptions: [],
      appliedFilters: {},
      filterList: null,
      recentList: [],
      isOpen: false,
      isLoading: false,
      pagination: undefined,
      currentPage: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getQueryParams();
    const selectedFilterOptions=this.getAllUrlParams(window.location.href)
    this.setState({
      selectedFilterOptions
    })
    this.recomndedVideo(0)
  }

  getQueryParams = () => {
    const url = window.location.href;
    const queryString = url.split('?')[1];
    if (!queryString) return [];

    const params = new URLSearchParams(queryString);
    let values: any = [];

    params.forEach((value) => {
      // Decode the URI component to handle encoded characters
      values.push(decodeURIComponent(value));
    });
    
    this.setState({
      selectedFilterOptions: values
    });
  }

  apiCall = (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  getApiCommonResponseDetail = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )
    return {apiRequestCallId, responseJson, errorReponse}
  }

  handleApiSuccessResponse = (message: Message) => {
    
    const {apiRequestCallId, responseJson, errorReponse} = 
    this.getApiCommonResponseDetail(message);
    if (responseJson && !responseJson.errors && !errorReponse) {
      if (apiRequestCallId === this.getFilterApiCall) {
        this.getFilterSuccessCallBack(responseJson.data)
        
      }
      if (apiRequestCallId === this.getRecentalyUploaded) {
        console.log(responseJson, "filterdata")
        this.setState({
          recentList: responseJson.data, isLoading: false,
          pagination: responseJson.meta.pagination,
          currentPage: responseJson.meta.pagination.current_page
        })
      //  this.getRecentalySuccessCallBack(responseJson.data)
      }
    }
  }

  handleApiErrorResponse = (message: Message) => {
    const {apiRequestCallId, responseJson, errorReponse} = this.getApiCommonResponseDetail(message);
    if (responseJson?.errors || !errorReponse?.errors) {
      if (apiRequestCallId === this.getFilterApiCall) {
        this.getFilterFailureCallBack()
      }
    }
  }

  getFilterSuccessCallBack(data: any) {
    this.setState({
      filterList:data||[],
      pagination: data.meta.pagination,
      currentPage: data.meta.pagination.current_page
    })
  }
  getFilterFailureCallBack() {
    console.log("search failure")
  }
  selectOption(value: string) {
    // Create local variables of state variables
    const filterOptions = [...this.state.selectedFilterOptions];
    const index = filterOptions.indexOf(value);
    const appliedFilters = this.state.appliedFilters;
    const selectedFilterLabel = this.state.selectedFilter?.key;
    const data = appliedFilters[selectedFilterLabel];

    if(index != -1){

      filterOptions.splice(index,1);
      const filteredArray = appliedFilters[selectedFilterLabel].filter((x: string) => x!=value);
      appliedFilters[selectedFilterLabel] = filteredArray;
      Object.keys(appliedFilters).forEach((key) => {
        if(!appliedFilters[key].length) {
          delete appliedFilters[key]
        }
      })
      this.setState({
        selectedFilterOptions: filterOptions,
        appliedFilters: appliedFilters
      }, ()=> console.log(this.state.appliedFilters));

    }else{
      if(!data){
        appliedFilters[selectedFilterLabel] = [value];
      }else{
        appliedFilters[selectedFilterLabel].push(value);
      }
      this.setState({
        selectedFilterOptions: [...filterOptions, value],
        appliedFilters: appliedFilters
      }, ()=> console.log(this.state.appliedFilters,"Hello Filtered Data"));
    }
  }

  applyFilter = (payload: any, page:number) => {
    console.log(localStorage.getItem("token"),"TOken conseol")
    const endpoint = localStorage.getItem("token") ?
    configJSON.filterOption :
    `${configJSON.filterOption}/?guest=true`;
    console.log(endpoint,"Endpoint")
    this.getFilterApiCall = this.apiCall({
     contentType: configJSON.filterApiContentType,
     method: configJSON.apiMethodTypePost,
     endPoint: endpoint,
     body: {q: payload}
    })

  }


  recomndedVideo = (page?:number) => {
    this.setState({isLoading:true})
    const endpoint = localStorage.getItem("token") ?
    `${configJSON.recentalyUploaded}?page=${page}` :
    `${configJSON.recentalyUploaded}?guest=true&page=${page}`;
    this.getRecentalyUploaded = this.apiCall({
      contentType: configJSON.filterApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: endpoint,
    })
  }

  applyAndRedirect= () => {
    const searchParams = new URL(window.location.href).searchParams.get('search');
    let queryParams=this.state.appliedFilters
    if(searchParams){
      queryParams = Object.assign(queryParams, {search: searchParams});
    }
    const myParms =  new URLSearchParams(queryParams).toString();
    window.location.href = `/Search-result?${myParms}`;
  }

  removeUnderScore = (value: string) => {
    return value.replace(/_/g, " ")
  }

  openPopover = () => {
    this.setState({ isOpen: true });
  };

  closePopover = () => {
    this.setState({ isOpen: false });
  };
  
  getAllUrlParams(urlString:string) {
    const url = new URL(urlString);
    const searchParams = url.searchParams;
    const queryParams = [] as any;
    const appliedFilters={} as any
    searchParams.forEach((value, key) => {
      appliedFilters[key]=value.split(',')
      queryParams.push(...value.split(','));
    });
    console.log("appliedFilters: ",appliedFilters)
    this.setState({
      appliedFilters
    })
    return queryParams
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiSuccessResponse(message);
      this.handleApiErrorResponse(message); 
    }

    // Customizable Area End
  }
}
