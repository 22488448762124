Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.filterApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.filterOption = "filter_items/filtering";
exports.recentalyUploaded = "bx_block_posts/posts";
exports.RecentlyText = "Recently Uploaded";
exports.ApplyText = "Apply";
exports.FiltersText = "Filters";
exports.ViewsText = "Views";
exports.NoDataText = "No data found";
exports.SearchResultsText = "Search results"


exports.apply = "Apply";
exports.recentUpload = "Recently Uploaded";
exports.filter = "Filters";
exports.views = "Views";
exports.noData = "No data found";
exports.searchResult = "Search results";

exports.filters = [
  {id:1,key:"video_type", label: "Type", items: ["Sport","Songs","story_time","crafts","warm_up","Challenge"] },
  {id:2,key:"sport", label: "Sports", items: ["Soccer","Basketball","Hockey","BaseBall","Football",
  "Volleyball",
  "Tennis",
  "Golf",
  "track_&_field",
  "Badminton","Bowling","Coordination","Ninja"] },
  {id:3, key:"season", label: "Season", items: ["Season1", "Season2", "Season3"] },
  {id:4, key:"skill", label: "Skill", items: ["Dribbling", "Throwing", "Catching", "Shooting", "Kicking", "Jumping", "Running", "Balancing", "Strength", "Skipping","Dancing"] },
  {id:5, key:"character", label: "Characters", items: ["Karma","Ronnie","Captain_candy_cane","dr._truble_narzo","queen_cat","dexter"] },
  {id:6, key:"mind", label: "Mind", items: ["winning_and_losing", "getting_upset", "growth_mindset", "critical_thinking", "concentration", "Adaptability", "Listening", "emotions_&_feelings", "empathy_and_compassion" , "self-control", "learning_how_to_learn", "happiness", "simplicity", "awareness", "patience", "sharing_&_teamwork", "forgiveness"] },
  {id:7, key:"growing_up", label: "Growing Up", items: ["bed_time", "bath_time", "eating_meals","going_potty","manners","using_words","putting_on_clothes","trust_mom_and_dad","ouchies","separation_anxiety","safety","hands_to_yourself","helping_with_chores","cleanup","confidence","practice_is_progress","biomimicry",
  "exploration","systems_thinking","language_building","bye-bye_binky","body_language","story_telling","left_&_right","telling_the_truth","variety","washing_hands","wellness"] },
  {id:8, key:"theme", label: "Theme", items: ["beach", "bam_city", "weather_and_storms", "space", "snow", "mystical", "emergency","services","school","jungle","birthday","circus",
  "board_games","playground","camping","mountains", "train", "technology", "cowboys/cowgirls", "video_games", "farm"] },
  {id:9, key:"length", label: "Length", items: ["shorts","10_minutes_or_less", "15_minutes_or_less", "20_minutes_or_less", "30_minutes_or_less"] },
];
// Customizable Area End