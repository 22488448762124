// App.js - WEB
import React, { Component } from 'react';
import WebRoutes from './WebRoutes';
import HomeScreen from '../../components/src/HomeScreen';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'antd/dist/antd.css';
import '../../blocks/email-account-registration/src/SignupPage.css';
import '../../blocks/email-account-registration/src/AccountCreated.css';
import '../../blocks/email-account-login/src/EmailLogin.css'
import '../../blocks/catalogue/src/Container.css';
import '../../blocks/catalogue/src/VideoSlider.css';
import '../../blocks/videos/src/videos.css';
import '../../blocks/notificationsettings/src/NotificationSetting.css';
// import "video-react/dist/video-react-subtitle.css";

// import '../../blocks/Settings5/src/favorite.css';
import '../../blocks/Settings5/src/terms.css';
import '../../blocks/OfflineBrowsing/src/OfflineContent.css';
import '../../blocks/catalogue/src/NavBar.css';
import '../../blocks/catalogue/src/Home.css';
import "../../blocks/Settings5/src/setting.css"
import '../../blocks/splashscreen/src/Splashscreen.css';
import 'video-react/dist/video-react.css';
import { notification } from 'antd';
// import { getTokenData } from './firebaseInit.js';
import { getTokenData ,onMessageListener} from './firebase';
// import { onMessageListener } from './firebaseInit';
import MyContext from './context'
// fonts
import "../../../fonts/Poppins-Bold.ttf";
import "../../../fonts/Poppins-Italic.ttf";
import "../../../fonts/Poppins-Medium.ttf";
import "../../../fonts/Poppins-Regular.ttf";
import "../../../fonts/Poppins-SemiBold.ttf";
// import "./App.css"
// import OfflineContent from '../../blocks/OfflineBrowsing/src/OfflineContent.web';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import OfflineBrowsing from '../../blocks/OfflineBrowsing/src/OfflineBrowsing.web';
import PlayVideo from '../../blocks/OfflineBrowsing/src/PlayVideo.web';
import { Redirect } from 'react-router-dom';


if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
  
}
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOffline: !navigator.onLine,
      count: 0,
    };
  }

  async componentDidMount() {
    const initialCount = localStorage.getItem('count');
    this.setState({ count: initialCount ? parseInt(initialCount, 10) : 0 });
    Notification.requestPermission().then((permission) => {
      console.log('@@@ Notification Permission ======', permission);
      // getTokenData()
      this.startMessageListener();

    });
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);

    const notificationCountChannel = new BroadcastChannel('notification-data');
    notificationCountChannel.onmessage = (event) => {
      const payload = event.data.notification;
      console.log('Received notification data:', payload);
      this.incrementCount()
    };
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  }

  handleOnline = () => {
    this.setState({ isOffline: false });
  };

  handleOffline = () => {
    this.setState({ isOffline: true });
    history.pushState('','','/download')
  };

  incrementCount = () => {
    this.setState((prevState) => ({ count: prevState.count + 1 }));
    localStorage.setItem('count', this.state.count);
  };

  // getTokenData = () => {
  //   return getToken(messaging, { vapidKey: 'BHIXCZVmvrHF7vW_O7oRQKaR0IXqGqIM-W9xRiVzyFBMChpXTq7sutOrNQefaEEx7XCi1wTSQbIqWzbLoWKOsp4' }).then((currentToken) => {
  //     if (currentToken) {
  //       console.log('@@@ Firebase Registration TOKEN =====', currentToken);
  //       localStorage.setItem('fcmToken', currentToken.toString())
  //     } else {
  //       console.log('@@@ Firebase No registration token available. Request permission to generate one.');
  //     }
  //   }).catch((err) => {
  //     console.log('@@@ Firebase Error: An error occurred while retrieving token. ', err);
  //     // catch error while creating client token
  //   });
  // }

  Dcount = () => {
    this.setState((prevState) => ({ count: 0 }));
  };

  startMessageListener = () => {
    console.log("SMeet")
    getTokenData();
    onMessageListener();
    // onMessageListener().then((payload) => {
    //   console.log('@@@ Notification Payload =======', payload);
    // }).catch((err) => console.log('sssss: ', err));
  }

  render() {
    const { isOffline } = this.state;
    const contextValue = {
      count: this.state.count,
      incrementCount: this.Dcount,
    }
    // const redirectToDownload = <Redirect to="/download" />
    const selectedVideo = localStorage.getItem('clickedVideoID');
    return (
      <Router>
        {isOffline ? (
          <>
          <MyContext.Provider value={contextValue}>
           {/* <Switch> */}
                <WebRoutes
                  path="/download"
                  exact
                  render={props => <OfflineBrowsing {...props} />}
                />
              {/* </Switch> */}
          </MyContext.Provider>
          </>
        ) : (
          <MyContext.Provider value={contextValue}>
            <WebRoutes {...this.props} />
          </MyContext.Provider>
        )}
      </Router>
    );
  }

}

export default App;
