//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
//* Routes List start
const Splashscreen = lazy(() => import('../../blocks/splashscreen/src/Splashscreen.web'));
const SignupPage = lazy(() => import('../../blocks/email-account-registration/src/SignupPage.web'));
const AccountCreated = lazy(() => import('../../blocks/email-account-registration/src/AccountCreated.web'));
const EmailLogin = lazy(() => import('../../blocks/email-account-login/src/EmailLogin.web'));
const ForgetPassword = lazy(() => import('../../blocks/forgot-password/src/Forgot_Password.web'));
const SetNewPassword = lazy(() => import('../../blocks/forgot-password/src/SetNewPassword.web'));
const HomePage = lazy(() => import('../../blocks/catalogue/src/HomePage.web'));
const Setting = lazy(() => import('../../blocks/Settings5/src/Settings5.web'));
const UserProfile = lazy(() => import('../../blocks/user-profile-basic/src/UserProfile.web'));
const Terms = lazy(() => import('../../blocks/Settings5/src/terms.web'));
const SearchResult = lazy(() => import('../../blocks/search/src/searchResult.web'));
const History = lazy(() => import('../../blocks/catalogue/src/History.web'));
const VideoPlayer = lazy(() => import('../../blocks/videos/src/Videos.web'));
const HelpCentre = lazy(() => import('../../blocks/helpcentre/src/HelpCentre.web'));
const Favourites = lazy(() => import('../../blocks/favourites/src/Favourites.web'));
const Search = lazy(() => import('../../blocks/search/src/Search.web'));
const Filter = lazy(() => import('../../blocks/filteritems/src/Filteroptions.web'));
const FilterResult = lazy(() => import('../../blocks/filteritems/src/filterResult.web'));
const OfflineBrowsing = lazy(() => import('../../blocks/OfflineBrowsing/src/OfflineBrowsing.web'));
const PlayVideo = lazy(() => import('../../blocks/OfflineBrowsing/src/PlayVideo.web'));
const Payment = lazy(() => import('../../blocks/SubscriptionBilling2/src/SuccessFailer.web'));
const Notificationsettings = lazy(() => import('../../blocks/notificationsettings/src/Notificationsettings.web'));
const Featured = lazy(() => import('../../blocks/videos/src/featurelist.web'));
const Gamification = lazy(() => import('../../blocks/Gamification/src/Gamification.web'));
const Subscription = lazy(() => import('../../blocks/SubscriptionBilling2/src/Subscription.web'));

// /* Routes List End /

// /* Private Routes start /
import PrivateRoute from './PrivateRoute.web';
import Loader from '../../components/src/Loader.web';

// /* Private Roues End /

function WebRoutes(rootProps: any) {
  return (
    <Router>
      <Suspense fallback={<Loader loading />}>
        <Switch>
          <Route
            path="/"
            exact
            render={props => <Splashscreen {...props} />}
          />
          <Route
            path="/signup"
            exact
            render={props => <SignupPage {...props} />}
          />
          <Route
            path="/emailLogin"
            exact
            render={props => <EmailLogin {...props} />}
          />
          <Route
            path="/accountCreated"
            exact
            render={props => <AccountCreated {...props} />}
          />
           <Route
            path="/forgetPassword"
            exact
            render={props => <ForgetPassword {...props} />}
          />
          {/* <Route
            path="/checkEmail"
            exact
            render={props => <CheckEmail {...props} />}
          /> */}
          <Route
            path="/password/reset-password/:token"
            exact
            render={props => <SetNewPassword {...props} />}
          />
          <Route
            path="/videoplayer/:id"
            exact
            render={props => <VideoPlayer {...props} />}
          />
          <Route
            path="/homePage"
            exact
            render={props => <HomePage {...props} />}
          />
           
           <Route
            path="/terms"
            exact
            render={props => <Terms {...props} />}
          />
          
           <Route
            path="/Search-result"
            exact
            render={props => <SearchResult {...props} />}
          />
          
          <Route
            path="/help"
            exact
            render={props => <HelpCentre {...props} />}
          />
          <Route
            path="/Search"
            exact
            render={props => <Search {...props} />}
          />
          <Route
            path="/Filter"
            exact
            render={props => <Filter {...props} />}
          />
          <Route
            path="/FilterResult"
            exact
            render={props => <FilterResult {...props} />}
          />
           <Route
            path="/download"
            exact
            render={props => <OfflineBrowsing {...props} />}
          />
           <Route
            path="/download/:id"
            exact
            render={props => <PlayVideo {...props} />}
          />
          <PrivateRoute
            path="/History"
            exact
            render={props => <History {...props} />}
          />
          <PrivateRoute
            path="/Favorites"
            exact
            render={props => <Favourites {...props} />}
          />
          <PrivateRoute
            path="/profile"
            render={props => <UserProfile {...props} />}
          />
          <PrivateRoute
            path="/setting"
            exact
            render={props => <Setting {...props} />}
          />

         <Route
            path="/payment/:id"
            exact
            render={props => <Payment {...props} />}
          />
          <Route
            path="/notificationsettings"
            exact
            render={props => <Notificationsettings {...props} />}
          />
          <Route
            path="/features"
            exact
            render={props => <Featured {...props} />}
          />
          <Route
            path="/gamification"
            exact
            render={props => <Gamification {...props} />}
          />
          <Route
            path="/subscription"
            exact
            render={props => <Subscription {...props} />}
          />
          
        </Switch>
      </Suspense>
    </Router>
  );
}

//@ts-ignore
export default withRouter(WebRoutes);
