Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.searchApiContentType = 'application/json'
exports.getSearchApiEndPoint = 'search/users'
exports.errorTitle = 'Error'
exports.apiMethodTypePost = 'POST'
exports.searchList = "bx_block_posts/auto_suggetions";
exports.searchListOnPress = "filter_items/filtering";
exports.NodataText = "No Record Found";
exports.ViewsText = "Views";
exports.SearchMainText = "Search results"
// Customizable Area End