import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string | null;
  searchItem: string;
  SearchKeyword: string;
  searchList: any;
  videoList: any;
  pagination: any;
  currentPage: number;
  params:string;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface ApiData {
  contentType: string; 
  method: string; 
  endPoint: string; 
  body?: Body; 
  type?: string;
}
interface Body {
  [key: string] : string | number[]
}

export default class SearchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // searchApiCallId: any;
  timer: any;
  getSearchApicall: string = "";
  getSearchSuggestionApiCall: string = "";
  getVideoApicall : string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];
    this.getVideoApicall = ""

    this.state = {
      // Customizable Area Start
      token: "",
      searchItem: "",
      SearchKeyword : "",
      searchList: null,
      videoList: [],
      pagination: undefined,
      currentPage: 1,
      params:"",
      isLoading: false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
   const token = localStorage.getItem("token")
   this.setState({token})
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.getSearchSuggestionApiCall) {
        this.getSearchSuggestionSuccessCallBack(responseJson)
      }
      if (apiRequestCallId === this.getSearchApicall) {
        this.getSearchSuccessCallBack(responseJson)
      }
      if (apiRequestCallId === this.getVideoApicall) {
        this.getVideoSuccessCallBack(responseJson.data)
      }
      if (responseJson && responseJson.data) {
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start

  apiCall = (data: ApiData) => {
    this.setState({isLoading:true})
    const { contentType, method, endPoint, body, type } = data
    const {token} = this.state
    const header = {
      'Content-Type': contentType,
      token
    }
    const apiCallMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      apiCallMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : apiCallMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(apiCallMessage.id, apiCallMessage);
    return apiCallMessage.messageId;
  }

  handleSearch = (e:any) => {
    this.setState({searchItem: e.target.value})
    this.debounceSearch(e.target.value)
  }
  onEnterClick = (event:any) => {
    if(event.key === 'Enter' && this.state.searchItem !== ""){
      this.searchVideo(this.state.searchItem);
    }
  }
  debounceSearch = (term:string) => {
    this.setState({SearchKeyword: term})
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchSearchResults(term);
    }, 500);
  };
  searchVideo = (search : string ) => {
    const queryString = window.location.href.split('?')[1];
    const queryParams = new URLSearchParams(queryString);
    const params: any = {};

    queryParams.forEach((value, key) => {
      // Decode the URI component to handle URL-encoded values
      if (params[key]) {
        // If the key already exists, add the value to an array
        params[key].push(decodeURIComponent(value));
      } else {
        // If the key doesn't exist, create an array with the value
        params[key] = [decodeURIComponent(value)];
      }
    });
    let myParams = Object.assign(params,{search});
    const urlParams=new URLSearchParams(myParams).toString()
    window.location.href = `/Search-result?${urlParams}`;
  }
  fetchSearchResults  = async  (searchKey: string) => {
    this.getSearchSuggestionApiCall  = this.apiCall({
     contentType: configJSON.searchApiContentType,
     method: configJSON.apiMethodTypePost,
     endPoint: configJSON.searchList,
     body: {"query": searchKey}
   })
  }
  getQuery = () => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      console.log(urlParams, "urlParams")
      const myParam = urlParams.get('search');
      return myParam;
    } else {
      return null; 
    }
  }
  handleSearchVideo = (params : any, page:number) => {
    let url;
    if (localStorage.getItem("token")) {
      url = `${configJSON.searchListOnPress}?page=${page}`;
    } else {
      url = `${configJSON.searchListOnPress}/?guest=true&page=${page}`;
    }
    let payload={
      query:new URL(window.location.href).searchParams.get('search') || ""
    }
    delete params["search"]
    Object.assign(payload,{q: params})
    this.getSearchApicall =  this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.apiMethodTypePost,
      endPoint: url,
      body: payload
    })

}

  getSearchSuggestionSuccessCallBack(data:any){
    console.log(data, "search list")
    this.setState({searchList:data})
    console.log(this.state.searchList , "Mock Search list")
  }
  getSearchSuccessCallBack(res:any) {
    const data=res.data
    if(data){
        this.setState({
          videoList:data,
          pagination: res.meta.pagination,
          currentPage: res.meta.pagination.current_page
      })
    }
    this.setState({
      isLoading: false
    })
  }
  getVideoSuccessCallBack(data: any) {
    console.log(data, "videolist")
    this.setState({videoList: data})
    
  }
  
  getAllUrlParams(urlString:string) {
    const url = new URL(urlString);

    const searchParams = url.searchParams;

    const queryParams = {} as any;

    searchParams.forEach((value, key) => {
      queryParams[key] = value.split(',');
    });
    console.log("queryParams: ",queryParams)
    return queryParams
  }

  // Customizable Area End
}
