import React from "react";
// Customizable Area Start
import { Button, Popover } from "antd";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
// Customizable Area End

import FilteroptionsController, {
  Props,
  configJSON
} from "./FilteroptionsController.web";

export default class Filteroptions extends FilteroptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <div className="Bam-NavBar-Button-Div">
        <Link to={'/FilterResult'} id="1" className="Bam-NavBar-filter-Button Display-None" data-test-id="recentUpload">
          {configJSON.RecentlyText}
        </Link>
        <Popover
          open={this.state.isOpen}
          content={
            <div className="filter_section">
              <div className="filter_action">
                <button
                  onClick={this.applyAndRedirect}
                  className="filterBtn"
                  data-test-id="applyFilter"
                  type="button"
                >
                  {configJSON.ApplyText}
                </button>

                <h5>
                  <FilterOutlined translate={undefined} />
                  {configJSON.FiltersText}
                </h5>
                <button
                  className="filterClose"
                  data-test-id="closepopover"
                  onClick={this.closePopover}
                >
                  <CloseCircleOutlined />
                </button>
              </div>
              <div className="filter_options" style={{display:"flex",flexWrap:"wrap"}}>
                {/* <span>Type</span> */}
                {configJSON.filters.map((data: any) => {
                  return (
                  <span
                    className={
                      this.state.selectedFilter.label === data.label
                        ? "active"
                        : ""
                    }
                    data-test-id="selectOption"
                    onClick={() => this.setState({ selectedFilter: data })}
                  >
                    {data.label}
                  </span>
                )})}
              </div>
              <hr />
              <div className="filterSeleted_options">
                {this.state.selectedFilter.items.map((items: any, key: any) => (
                  <span
                    className={
                      this.state.selectedFilterOptions.includes(items.toLowerCase())
                      ? "active"
                      : ""
                    }
                    onClick={() => {
                      this.selectOption(items.toLowerCase());
                    }}
                    >
                    {this.removeUnderScore(items)}
                  </span>
                ))}
              </div>
            </div>
          }
          trigger="click"
          placement="topLeft"
        >
          <Button
            id="6"
            className="Bam-NavBar-FilterOutlined-Button"
            data-test-id="openpopover"
            onClick={() => this.openPopover()}
          >
            <FilterOutlined translate={undefined} /> {configJSON.FiltersText}
          </Button>
        </Popover>
      </div>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End