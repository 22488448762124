import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Row, Col } from "antd";
import NavBar from "../../catalogue/src/NavBar.web";
import SideMenuBar from "../../catalogue/src/SideMenuBar.web";
import { Discover } from "./assets";
import {
    Player,
    ControlBar,
    PlayToggle,
    ForwardControl,
    ClosedCaptionButton,
    ReplayControl,
    VolumeMenuButton,
  } from "video-react";
  import { Link } from 'react-router-dom';

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import OfflineBrowsingController, {
    Props,
} from "./OfflineBrowsingController";

export default class PlayVideo extends OfflineBrowsingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        const currentVideoID = this.props.match.params.id;
        const videoData = this.state.downloadedVideos.find((video:any) => video.videoID === currentVideoID);
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <div className="Bam-Home">
                    <SideMenuBar />
                    <div className="Bam-Home-Container-Div">
                        <Row>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24} 
                                 className="top-header"><NavBar /></Col>
                        </Row>
                        <Row className="main-section">
                            <Col lg={18} md={24} sm={24} xs={24} className="main-video-section">
                            <div className="videos-container"> 
                            {videoData && <div id="videos-container" >
                                    {videoData.videoURL && 
                                    <Player 
                                        autoPlay 
                                        poster={videoData.videoThumb}
                                        ref={(player) => {
                                            this.playerRef = player;
                                        }}
                                        key={videoData.videoID}
                                        >
                                        <source src={videoData.videoURL} />
                                        <track src={this.state.subtitle} label="English" kind="captions" srcLang="en" />
                                        <ControlBar autoHide={false}>
                                        <PlayToggle />
                                        <VolumeMenuButton vertical />
                                        <ReplayControl seconds={5} />
                                        <ForwardControl seconds={5} />
                                        <ClosedCaptionButton order={7} />
                                    </ControlBar>
                                    </Player>
                                    }
                                        <h4>{videoData.videoName}</h4>
                                       <span>{videoData.videoView} Views</span>
                                </div>
                            }
                            </div>
                            </Col>
                            <Col lg={6} md={24} sm={24} xs={24}>
                                <p>
                                    <img
                                        src={Discover}
                                        alt="Discover"
                                        className="Bam-Home-Side-Sub-Menu-Image"
                                    />
                                    Your Downloads
                                </p>
                                {this.state.downloadedVideos.map((videoData:any) => {
                                    return (
                                        <Link key={videoData.videoID} to={`/download/${videoData.videoID}`}>
                                            <div className={`card ${videoData.videoID === currentVideoID ? 'current-video' : ''}`} id="card">
                                                <div className='image-section'>
                                                    <img src={videoData.videoThumb} alt="Avatar" className="Video-Slider-card-img" />
                                                </div>
                                                <div className="Video-Slider-container">
                                                    <p className='Video-Slider-container-Heading-Text'>{videoData.videoName}</p>
                                                    <p className='video-slider-view-count'>{videoData.videoView} Views</p>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                })}

                            </Col>
                        </Row>
                    </div>

                </div>

            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
