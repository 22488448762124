import React from "react";
// Customizable Area Start
import { Input, Badge, Button, Menu, Modal, Row, Col } from "antd";
import {
  SearchOutlined,
  BellOutlined,
  MenuFoldOutlined,
  FilterOutlined
} from "@ant-design/icons";
import { Link } from 'react-router-dom';
import { Logo, user, Logout, NotificationDots,Help } from "./assets";
import HomePageController from "./HomePageController.web";
import WithBrandingSpinner from "../../../components/src/WithBrandingSpinner.web";
import CustomModal from "../../../components/src/customWebComponent/modal.web";
import moment from "moment";
import Filteroptions from "../../filteritems/src/Filteroptions.web";
import Search from "../../search/src/Search.web";
import MyContext from "../../../web/src/context";
export const configJSON = require("./config.js");

// Customizable Area End

// Customizable Area Start
class NavBar extends HomePageController {
  static contextType = MyContext;

  async componentDidMount() {
    this.getProfileApicall = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.userProfile,
    });
    this.notificationAPI()
  }



  notificationCard = () => {
    return (
      <>
        {this.state.loading && <p className="loadingText">Loading</p>}
        {this.state.notificationData.length ? (
          this.state.notificationData?.map((item: any,index: number) => {
            return (
              <>
                  <Row className="Notifications-Card">
                    <Col span={2} className="">
                      <i className="fa fa-user-circle-o Notifications-UserPrifile" />
                    </Col>

                    <Col span={13} className=" Notifications-card-Text-div">
                      {/* <Link to`/videoplayer/${}`> </Link> */}
                      <Link
                  to={`/videoplayer/${item.attributes.push_notification.data.attributes.push_notificable_id}`}
                >
                      <p>
                        <span className="Notifications-card-Text">
                          {
                            item.attributes.push_notification.data.attributes
                              .remarks
                          }
                        </span>{" "}
                        Uploaded a new video in on his channel
                      </p>
                      </Link>
                      <p className="Notifications-card-View-Text">
                        {" "}
                        {this.timeFormat(
                          item.attributes.push_notification.data.attributes
                            .created_at
                        )}
                      </p>
                      
                    </Col>
                    <Col span={8} className="Notifications-card-image-div">
                      <img
                        src={
                          item.attributes.push_notification.data.attributes
                            .image_url
                        }
                        alt="Avatar"
                        className="Notifications-card-img"
                      />
                    </Col>
                    <Col span={1} className="notification-actions">
                      <button className="notification-dots" onClick={() => this.notificationAction(index)}>
                        <img src={NotificationDots} />
                      </button>
                    </Col>
                  </Row>
                  {this.state.visibleNotificationAction[index] &&
                    <div className="notification-setting-actions">
                      <Link to={"/notificationsettings"} className="notification-btn">
                      <BellOutlined
                      className="Bell-Notifications-Icon"
                      translate={undefined}
                    /> Notification Setting</Link>
                      <Link to={"/help"} className="help-btn">
                       <img src={Help} width="15px" />  Help & Feedback
                      </Link>
                    </div>
                  }
                

                <div className="Bam-Home-Side-Menu-border" />
              </>
            );
          })
        ) : (
          <p className="loadingText">No Record Found</p>
        )}
      </>
    );
  };

  render() {
    // Customizable Area Start
    /* istanbul ignore next */
    const { count,incrementCount } = this.context;
   


    const CustomMenu = (props: any) => {
      return (
        <Menu.Item
          className={`Bam-menuItemOption ${window.location.pathname ===
            props.link && "ant-menu-item-selected"}`}
          key={props.id}

        >
          <img
            src={require(`../assets/${props.img}`)}
            alt={props.img}
            className="Bam-Home-Side-Sub-Menu-Image"
          />
          {props.label}
        </Menu.Item>
      );
    };

    return (
      <div className="Bam-NavBar">
        <div className="Bam-NavBar-search-input-Div">
          <Search navigation={undefined} id={""} />
          <div  data-test-id="notificationbuttonP"   className="Bam-Home-Bell-Div">
            <details className="dropdown">
              <summary role="button" className="dropdownsummary">
                <Badge count={this.state.isShow?this.state.totalCount:0}>
                  {/* } */}
                  {/* <BellOutlined
                    className="Bam-Home-Bell"
                    translate={undefined}
                    onClick={updateCountInContext}
                  /> */}
{localStorage.getItem("token") ? 
                  <BellOutlined
                  data-test-id="notificationbutton" 
                    type="BellOutlined"
                    className="Bam-Home-Bell"
                    translate={undefined}
                    onClick={()=>{
                      this.updateCountInContext(count , incrementCount)
                    }}

                  /> : null}
                </Badge>
              </summary>
              <ul onScroll={this.handleScroll} >
                <li>
                  <div className="Bell-Notifications">
                    <BellOutlined
                      className="Bell-Notifications-Icon"
                      translate={undefined}
                    />{" "}
                    &nbsp; Notification
                  </div>
                  <div className="Notifications-Main-Div " >
                    {this.notificationCard()}
                  </div>
                  <div className="Notifications-btn-div">
                    <Button className="Notifications-Clear-btn" onClick={this.clearAll}>
                      Clear All Notifications
                    </Button>
                  </div>
                </li>
              </ul>
            </details>

            {
              localStorage.getItem("token") ? <Link
                to={"/profile"}
              >
                {!this.state.profileImageUrl ?
                  (<i className="fa fa-user-circle-o Bam-Navbar-UserPrifile" />)
                  : (<img src={this.state.profileImageUrl} className="fa fa-user-circle-o Bam-Navbar-UserPrifile-login" />
                  )}
              </Link> :
                <i data-test-id="userprofile" onClick={() => this.handleMenuClick("profile")} className="fa fa-user-circle-o Bam-Navbar-UserPrifile" />
            }


            <MenuFoldOutlined
              data-testid="showModalclick"
              onClick={this.showModal}
              className="Bam-Home-Bell Bam-Home-Side-Menu"
              translate={undefined}
            />
          </div>
        </div>
        <Filteroptions id={""} navigation={this.props.history} />
        <Modal
          title=""
          visible={this.state.SideNavBar}
          onCancel={this.handleCancelNav}
          className="Bam-NavBar-Modal"
          data-testid="handleCancelNav"
          footer={null}
          width={350}
        >
          <div className="Bam-NavBar-Modal-Logo-Div">
            <img src={Logo} alt="Logo" className="Bam-NavBar-Modal-Logo" />
          </div>
          <div className="Bam-Home-Side-Menu-border" />
          <div className=" Bam-Home-Profile-Div">
            <div className="Bam-Home-Profile-Section ">
              {
                (localStorage.getItem("token") && this.state.profileImageUrl) ?
                 <img src={this.state.profileImageUrl} className="Bam-Home-UserPrifile" /> :
                  <i className="fa fa-user-circle-o Bam-Navbar-UserPrifile" />
              }
              <div>{this.state.fullName}</div>
            </div>
          </div>
          <div className="Bam-Home-Side-Menu-border" />
          <div className="Bam-Home-Side-Menu-Video-Settings">
            <Menu
              className="Bam-Home-Side-Menu-Heading"
              onClick={this.handleClick}
              data-testid="menuClick"
              mode="inline"
              selectedKeys={['tmp_key-tmp_key-0-1']}
            >
              <Menu.ItemGroup className="Bam-Home-Side-Sub-Menu">
                {Object.keys(configJSON.menuItem).map((menu: any) => {
                  return (
                    <>
                      <p className="Bam-Home-Side-Menu-Settings-p">{menu}</p>
                      {configJSON.menuItem[menu].map((subMenu: any) => {
                        return (
                          <>
                            {localStorage.getItem("token") || subMenu.isGuest ? (
                              <Link to={subMenu.link} className="Bam-sideManuOption">
                                {CustomMenu(subMenu)}
                              </Link>
                            ) : <div data-test-id="modalguest" className="Bam-sideManuOption" onClick={() => this.setState({ openModal: true, modalImage: subMenu.modalImg, modalTitle: subMenu.modalTitle })}>
                              {(
                                CustomMenu(subMenu)
                              )}
                            </div>}
                          </>
                        );
                      })}
                      <div className="Bam-Home-Side-Menu-border"></div>
                    </>
                  );
                })}
              </Menu.ItemGroup>
              {
                localStorage.getItem("token") ? 
              <Link to={"/emailLogin"} className="Bam-sideManuOption">
                <Menu.Item
                  className="Bam-menuItemOption"
                  onClick={this.handleLogout}
                >
                  <img className="Bam-Home-Side-Sub-Menu-Image" src={Logout} />
                  Logout
                </Menu.Item>
              </Link>
                : ""
              }
            </Menu>
          </div>
        </Modal>
        <CustomModal
          openModal={this.state.openModal}
          handleCancel={this.handleCancel}
          customImage={<img src={user} alt="this.state.modalImag" />}
          title={this.state.modalTitle}
        />

      </div>
    );
  }
}

export default WithBrandingSpinner(NavBar);
export { NavBar };
// Customizable Area End