import React from 'react'
// Customizable Area Start
import { Menu, Slider, Modal,Progress } from "antd";
import {
    Logo,
    cloud,
    profile_logo,
    Logout
} from "./assets"
import { Link } from 'react-router-dom';
import HomePageController from './HomePageController.web';
import WithBrandingSpinner from "../../../components/src/WithBrandingSpinner.web";
import CustomModal from "../../../components/src/customWebComponent/modal.web"
export const configJSON = require("./config.js");
// Customizable Area End

 // Customizable Area Start
class SideMenuBar extends HomePageController {
  async componentDidMount(){
    this.getProfileApicall = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.userProfile,
    })
    this.getBadgeApicall= await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.badgeList,
    })
  }

    render() {
       
        const CustomMenu = (props:any) => {
            return ( 
            <Menu.Item 
                    className={`Bam-menuItemOption ${window.location.pathname === props.link && 'ant-menu-item-selected'}`}
                    key={props.id}
                >
                    <img src={require(`../assets/${props.img}`)} alt={props.img} className='Bam-Home-Side-Sub-Menu-Image' />
                    {props.label}
                </Menu.Item>
         
            )
        }

        const userProfileContent = localStorage.getItem("token") ? (
          <Link to="/profile">
            <i
              data-test-id="userprofile"
              onClick={() => this.handleMenuClick("profile")}
              className="fa fa-user-circle-o Bam-Navbar-UserPrifile"
            />
          </Link>
        ) : (
          <i
            data-test-id="userprofile"
            onClick={() => this.handleMenuClick("profile")}
            className="fa fa-user-circle-o Bam-Navbar-UserPrifile"
          />
        );



        return (
            <div>
                <div className='Bam-Home-Side-Menu-Div'>
                    <div className='Bam-Home-Logo-Div'>
                        <img src={cloud} alt='Logo' className='Bam-Home-cloud1'></img>
                        <img src={Logo} alt='Logo' className='Bam-Home-Logo'></img>
                    </div>

                    <div className='Bam-Home-Side-Menu-border'></div>

                    <div className=' Bam-Home-Profile-Div'>
                        <img src={cloud} alt='Logo' className='Bam-Home-cloud2 '></img>
                        <div className="Bam-Home-Profile-Section ">
                          {
                            (localStorage.getItem("token") && this.state.profileImageUrl) ? <Link 
                            to={"/profile"}
                            > 
                              {this.state.profileImageUrl == null ? 
                              ( <i className="fa fa-user-circle-o Bam-Navbar-UserPrifile" /> ) 
                              : (<img src={this.state.profileImageUrl} className="fa fa-user-circle-o Bam-Navbar-UserPrifile-login" />
                              )}
                            </Link> : userProfileContent
                          }

                            <div>{this.state.fullName}</div>

                            <div className="Bam-Home-card-section card-height">
                                <img src={profile_logo} className="Bam-Home-card-Star-icon " />
                                <p>{this.state.receivedBadge }/{this.state.totalBadge} grade around</p>

                  <div className="awardsSection1">
                  <Progress  percent={(this.state.receivedBadge / this.state.totalBadge ) * 100} />
                  <div className="progressLevel">
                  </div>
                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Bam-Home-Side-Menu-border'></div>
                    <div className='Bam-Home-Side-Menu-Video-Settings'>
                        <img src={cloud} alt='Logo' className='Bam-Home-cloud3'></img>
                        <Menu
                            className='Bam-Home-Side-Menu-Heading'
                            datatest-id="menuClick"
                            mode="inline"
                            selectedKeys={['tmp_key-tmp_key-0-1']}
                        >
                            
                            <Menu.ItemGroup className='Bam-Home-Side-Sub-Menu'>
                                
                                {
                                    Object.keys(configJSON.menuItem).map((menu: any, index) => {
                                        return (
                                            <>
                                    <p className='Bam-Home-Side-Menu-Settings-p'>{menu}</p>

                                    {configJSON.menuItem[menu].map((subMenu: any) => {
                                      return (
                                        <>
                                          {localStorage.getItem("token") ? (
                                            <Link
                                              to={subMenu.link}
                                              className="Bam-sideManuOption"
                                            >
                                              {CustomMenu(subMenu)}
                                            </Link>
                                          ) : subMenu.isGuest == true ? (
                                            <Link
                                              to={subMenu.link}
                                              className="Bam-sideManuOption"
                                            >
                                              {CustomMenu(subMenu)}
                                            </Link>
                                          ) :<div className="Bam-sideManuOption"  onClick={()=> 
                                            this.setState({ openModal: true, modalImage:subMenu.modalImg, modalTitle: subMenu.modalTitle})
                                         }>{ (
                                            CustomMenu(subMenu)
                                          )}</div>}
                                        </>
                                      );
                                    })}

                      <div className="Bam-Home-Side-Menu-border"></div>
                    </>
                  );
                })}
              </Menu.ItemGroup>
              {
                localStorage.getItem("token") ? 
                <Menu.Item
                  className="Bam-menuItemOption"
                  onClick={this.handleLogout}
                >
                  <img className="Bam-Home-Side-Sub-Menu-Image" src={Logout} />
                  Logout
                </Menu.Item>
              : ""
              }
            </Menu>
          </div>
        </div>
        <CustomModal 
          openModal={this.state.openModal}
          handleCancel = {this.handleCancel}
          customImage= {<img src={this.state.modalImage} alt="this.state.modalImag"/>} 
          title = {this.state.modalTitle}
        />
      </div>
    );
    }
}
export default WithBrandingSpinner(SideMenuBar);
export { SideMenuBar };

// Customizable Area End