import React from "react";
// Customizable Area Start
import { Input } from "antd";
import {
  SearchOutlined,
} from "@ant-design/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
export const configJSON = require("./config.js");
// Customizable Area End

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#0000ff",
//       contrastText: "#fff"
//     }
//   }
// });
// Customizable Area End

import SearchController, { Props } from "./SearchController.web";

export default class Search extends SearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
        <div className="Bam-NavBar-search-list">
           <Input
             data-test-id="search_bar_test"
             size="large"
             placeholder="Search your video here...."
             className="Bam-NavBar-search-input"
             prefix={<SearchOutlined translate={undefined} />}
             onChange={(e) => this.handleSearch(e)}
             onKeyPress={(e) => this.onEnterClick(e)}
             defaultValue ={ this.getQuery() || this.state.SearchKeyword || ""}
             required
           />
           
           <div className="searchIconright" onClick={() => this.state.searchItem  !== "" && this.searchVideo(this.state.searchItem)}>
            <SearchOutlined />
           </div>
           
            {
              this.state.searchItem && (!this.state.searchList ?  <div className="Bam-search-list">{console.log(this.state.searchList, "searchList")}<CircularProgress /></div> :
              ((this.state.searchList?.length > 0) ?
             <div className="Bam-search-list">
               <ul>

                 {this.state.searchList.map((searchQuery: any) => 
                   <li onClick={() => this.searchVideo(searchQuery)}>

                         <SearchOutlined/> {searchQuery}

                   </li>
               )}
               </ul>
             </div>
             :
             <div className="Bam-search-list">{configJSON.NodataText}</div>)) 
            }
           
      
         </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};
// Customizable Area End