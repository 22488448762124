import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Card, Row, Col, Modal } from "antd";
import NavBar from "../../catalogue/src/NavBar.web";
import SideMenuBar from "../../catalogue/src/SideMenuBar.web";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import VideoSlider  from "../../catalogue/src/VideoSlider.web";
import { Discover, Delete, DeleteVideo } from "./assets";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import OfflineBrowsingController, {
  Props,
  configJSON,
} from "./OfflineBrowsingController";

export default class OfflineBrowsing extends OfflineBrowsingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <div className="Bam-Home">
        <SideMenuBar />
        <ToastContainer />
        <div className="Bam-Home-Container-Div">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="top-header">
              <NavBar />
            </Col>
          </Row>
          <div className="main-section-div my-offline-section">
            <div className="favourite_text">
              <h4><b>{configJSON.downloadText}</b></h4>
              <p>{configJSON.downloadDes}</p>
            </div>
              <div className="search-result-section">
            {this.state.downloadedVideos.length > 0 ? (
             this.state.downloadedVideos.map((cachedData:any, index:any) => (<>
                  <Card key={index} className="video-card">
                    <div  className="image-container">
                      <div className="duration-offline">
                        <Link to={`/download/${cachedData.videoID}`}>
                           <img src={cachedData.videoThumb}  
                                width={200} 
                                height={130}
                                data-test-id = 'open-current-video'
                                className="open-current-video"
                                onClick={() => {
                                  const data = cachedData.videoID;
                                  this.showVideo(data);
                                  localStorage.setItem('clickedVideoID', cachedData.videoID);
                                }}/>
                        </Link>
                      </div>
                    </div>
                    <div className="download-page">
                      <h6>{cachedData.videoName}</h6>
                      <div className="delete-fvrt">
                      <span>{cachedData.videoView} Views</span>
                      <div className="Notifications-btn-div-offline">
                        <Button style={{padding:"10px 30px",outline:"none"}} className="Notifications-Clear-btn" 
                            onClick={() => {
                              const videoData = cachedData.videoID;
                              this.showModal(videoData);
                            }}
                            data-test-id = 'offline-video' >
                          <img className="delete-video" src={Delete}/>
                          {configJSON.deleteText}
                        </Button>
                      </div>
                      </div>
                      </div>
                  </Card>
                  </>))
            ) : (
              <Typography variant="h6">{configJSON.noVideoTest}</Typography>
            )}

              </div>
            {/* )} */}
          </div>
          <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="Video-conatiner"
            >
              <div className="conatiner_heading-Video-Slider">
                <img
                  src={Discover}
                  alt="Discover"
                  className="Bam-Home-Video-Slider-Topic-Icon"
                />
                <p>{configJSON.RecommendedVideos}</p>
              </div>
              <div>
                <VideoSlider />
              </div>
            </Col>
          </Row>
        </div>
        <Modal className="modal-data" open={this.state.isModalOpen} onOk={this.handleOk} onCancel={this.handleCancel} okText= "Delete" cancelText="No" closeIcon=''>
            <img src={DeleteVideo} />
        <p>{configJSON.deleteVideo}</p>
      </Modal>
      
        </div>
  
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
