import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  match?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  downloadedVideos: any;
  selectedVideoIndex:any;
  isModalOpen:any;
  currentVideoID: any;
  currentlyOpenedVideoID: any;
  subtitle: any;
  activeVideoID: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OfflineBrowsingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      downloadedVideos: [],
      selectedVideoIndex: -1, 
      isModalOpen: false,
      currentVideoID: '',
      currentlyOpenedVideoID: '',
      subtitle: '',
      activeVideoID: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  showVideo = (videoID:any) => {
    this.setState({
      currentlyOpenedVideoID: videoID, 
    });
  };

  showModal = (videoData:any) => {
    this.setState({
        isModalOpen: true, 
        currentVideoID: videoData
      });
  };

  deleteVideoFromCache(currentVideoID:any) {
    const cachesToDeleteFrom = ['videoMetadataChild', 'videoMetadata'];
  
    cachesToDeleteFrom.forEach((cacheName) => {
      caches.open(cacheName).then((cache) => {
        cache.delete(`/videoplayer/${currentVideoID}`).then((deleted) => {
          this.componentDidMount()
        }).catch((error) => {
          console.error(`Error deleting video from ${cacheName} cache:`, error);
        });
      });
    });
  }
  

   handleOk = () => {
    const { currentVideoID } = this.state;
    this.deleteVideoFromCache(currentVideoID);
    this.setState({
        isModalOpen: false
      });

  };

   handleCancel = () => {
    this.setState({
        isModalOpen: false
      });
  };

  playerRef: any = React.createRef();

  VerifySuccess = (videoSubtitles: any) => {
    if (videoSubtitles !== undefined) {
      const request = new XMLHttpRequest();
      request.open('GET', videoSubtitles, true);
      request.responseType = 'blob';
      request.onload = () => {
          const reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload = this.setSubtitle.bind(this) 
      };
      request.send();
    }
  };

  setSubtitle = (event:any) => {
    this.setState({subtitle: event.target.result})
  }

/* istanbul ignore next */
async componentDidMount() {
  try {const primaryData = localStorage.getItem('primaryData');
      let metadataCacheName;
      if (primaryData) {
          metadataCacheName = 'videoMetadataChild';
      } else {
          metadataCacheName = 'videoMetadata';
      }
      const cache = await caches.open(metadataCacheName);
      const keys = await cache.keys();
      const cachedVideos = await Promise.all(
          keys.map(async (key) => {
              try {
                  const cachedResponse = await cache.match(key);

                  if (cachedResponse) {
                      const cachedData = await cachedResponse.json();
                      return {
                          videoID: cachedData.videoID,
                          videoURL: cachedData.videoURL,
                          videoName: cachedData.videoName,
                          videoThumb: cachedData.videoThumb,
                          videoView: cachedData.videoView,
                          videoSubtitles: cachedData?.videoSubtitles
                      };
                  } else {
                      console.error(`No cached response found for key: ${key.url}`);
                      return null;
                  }
              } catch (error) {
                  console.error(`Error reading cached data for key: ${key.url}`, error);
                  return null;
              }
          })
      );

      // Filter out any null values (entries with no cached response)
      const validCachedVideoData = cachedVideos.filter(data => data !== null);

      this.setState({
          downloadedVideos: validCachedVideoData,
      });

  } catch (error) {
      console.error('Error in componentDidMount:', error);
  }
}


  // Customizable Area End
}
