import React from 'react'
import { Modal } from "antd";
import { Link } from 'react-router-dom';
import {cloud, Heart, user} from "../../../blocks/catalogue/src/assets"

const CustomModal = (props:any) => {
    const {openModal, handleCancel, customImage, title}= props
    const renderModalData = () => {
        return (
          <>
            {renderModalImage()}
            <div>
              <div className="Login-Modal-Div ">
                {customImage}
              </div>
              <div className="Bam-Wants-To-Signin">
                <p>{title}</p>
              </div>
              <div className="Bam-share-information">
                <p>
                To enable this feature, user needs to <br/> Login/Signup first.
                </p>
              </div>
              {renderModalButton()}
            </div>
          </>
        );
      };
      const renderModalImage = () => {
        return (
          <>
            <img src={cloud} alt="cloud" className="cloud_Dark1" />
            <img src={cloud} alt="cloud" className="cloud_Dark2" />
            <img src={cloud} alt="cloud" className="cloud_Dark3" />
          </>
        );
      };
      
    
      const renderModalButton = () => {
        return (
          <>
            <div className="Login-Modal-btn-Div ">
              <Link
                className="Bam-Modal-Continue-btn"
                data-testid={"googleLogiButtonID"}
               to={"/emailLogin"}
              >
                Login
              </Link>
              <Link className="Bam-Modal-cancel-btn"
               to={"/signup"}
              >
                Create Account
              </Link>
            </div>
          </>
        );
      };
  
      return (
    <Modal 
        onCancel={handleCancel}
        visible={openModal} 
        className="Login-Modal"
        >
          {renderModalData()}
        </Modal>
  )
}

export default CustomModal