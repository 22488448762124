import { Workbox } from 'workbox-window';
export default function () {
    window.onload = function() {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/sw.js')
              .then((registration) => {
                  console.log('ServiceWorker registration successful with scope:', registration.scope);
              })
              .catch((error) => {
                  console.log('ServiceWorker registration failed:', error);
              });
        }
      };
}
