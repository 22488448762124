Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePost = "POST";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.videolistApiEndPoint = "bx_block_posts/posts";
exports.userProfile = "account_block/accounts/show";
exports.videoList = "bx_block_posts/posts/play";
exports.featureList = "bx_block_posts/featured";
exports.trandingList = "bx_block_posts/tranding?count=3";
exports.searchList = "bx_block_posts/auto_suggetions";
exports.searchListOnPress = "bx_block_posts/search";
exports.discoverList = "recomandations/get_videos";
exports.badgeList = "accounts/badge_stage";
const { Discover, Featured, Heart, Settings, History, Download ,TermsConditions } = require('./assets.ts');

exports.menuItem = {
  video: [
    {
      "key": "1",
      "img": "Discover.png",
      "label": "Discover",
      "link": "/homePage",
      "isGuest": true,
      "modalImg": Discover,
      "modalTitle": "Discover"
    },
    {
      "key": "2",
      "img": "Featured.png",
      "label": "Featured",
      "link": "/features",
      "isGuest": false,
      "modalImg": Featured,
      "modalTitle": "Featured"
    },
    {
      "key": "3",
      "img": "Favorites.png",
      "label": "Favorites",
      "link": "/Favorites",
      "isGuest": false,
      "modalImg": Heart,
      "modalTitle": "Favorites"
    },
  ],
  Settings: [
    {
      "key": "4",
      "img": "Settings.png",
      "label": "Settings",
      "link": "/setting",
      "isGuest": false,
      "modalImg": Settings,
      "modalTitle": "Setting"
    },
    {
      "key": "5",
      "img": "History.png",
      "label": "History",
      "link": "/history",
      "isGuest": false,
      "modalImg": History,
      "modalTitle": "History"
    },
    {
      "key": "9",
      "img": "Terms & Conditions.png",
      "label": "Subscription",
      "link": "/subscription",
      "isGuest": false,
      "modalImg": TermsConditions,
      "modalTitle": "Subscription"
    },
    {
      "key": "6",
      "img": "Downloads.png",
      "label": "Downloads",
      "link": "/download",
      "isGuest": false,
      "modalImg": Download,
      "modalTitle": "Download"
    },
    {
      "key": "7",
      "img": "Help.png",
      "label": "Help & Feedback",
      "link":"/help",
      "isGuest": true,
    },
    {
      "key": "8",
      "img": "Terms & Conditions.png",
      "label": "Terms & Conditions",
      "link": "/terms",
      "isGuest": true,
    },
  ]
}


// Customizable Area End