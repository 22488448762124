import React from 'react';
// Customizable Area Start
import Slider from "react-slick";
import { Card, Space } from 'antd';
import { Paid } from "./assets";
import HomePageController from './HomePageController.web';
import {Link} from "react-router-dom";
import WithBrandingSpinner from "../../../components/src/WithBrandingSpinner.web";
export const configJSON = require("./config.js");
const { Meta } = Card;
// Customizable Area End

 // Customizable Area Start
class VideoSlider extends HomePageController {

  async componentDidMount(){
    this.getVideoApicall = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.videoList,
    })
    this.getFeaturedApicall = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.featureList,
    })
  }


    // Customizable Area End
renderSliderData = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
            }
          },
          {
            breakpoint: 1150,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          }, {
            breakpoint: 680,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
  
        ]
      };
    return (
      <>
          <Slider {...settings}>
            {this.state.featureList.map((video: any)=>{
                return (
                    <Link to={`/videoplayer/${video.id}`}>
                      <div className="card"  >
                      <div className='image-section'>
                        <Space >
                          <span className="site-badge-count-109"
                          >{video.attributes.duration}</span>
                        </Space>
                        <img src={video.attributes.image_url} alt="Avatar" className="Video-Slider-card-img" />
                      </div>
                      <div className="Video-Slider-container">
                        <p className='Video-Slider-container-Heading-Text'>{video.attributes.name}</p>
                        <p className='video-slider-view-count'>{video.attributes.view_count} Views</p>
                        {video.attributes.avail_status === "paid" && ( <p className='paid-member'><img src={Paid}/></p>)}
                      </div>
                      </div>
                    </Link>
                 
                )
            })}
        
        </Slider>
         </>
    )
}
    // Customizable Area Start
// Customizable Area End
  render() {
// Customizable Area Start
  // console.log(this.state.videoList, "video list")
    return (
      <div>
        {this.renderSliderData()}
      </div>
    )

// Customizable Area End
  }
}
export default WithBrandingSpinner(VideoSlider);
export { VideoSlider };
    // Customizable Area End
    
// Customizable Area Start
// Customizable Area End