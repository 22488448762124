 /* istanbul ignore next */
 export const Discover = require("../../../../assets/web/Discover.png");
 export const Featured = require("../../../../assets/web/Featured.png");
 export const Logo = require("../../../../assets/web/B.A.M._Primary-Logo__1_.png");
 export const cloud = require("../../../../assets/web/cloud copy 11.png");
 export const Downloads = require("../../../../assets/web/Downloads.png");
 export const profile_logo = require("../../../../assets/web/Group 5 Copy 2.png");
 export const Favorites = require("../../../../assets/web/Favorites.png");
 export const Help = require("../../../../assets/web/Help.png");
 export const Settings = require("../../../../assets/web/Settings.png");
 export const Terms = require("../../../../assets/web/Terms & Conditions.png");
 export const History = require("../../../../assets/web/History.png");
 export const kid_Group = require("../../../../assets/web/Group 9.png");
 export const Heart = require("../../../../assets/web/heart.png");
 export const Download = require("../../../../assets/web/cloud-computing.png");
 export const user = require("../../../../assets/web/user.png");
 export const Streamer =  require("../../../../assets/web/streamer.png");
 export const Logout =  require("../../../../assets/web/logout.png");
 export const Meenu = require("../../../../assets/web/Menu.png");
 export const Cartoon = require("../../../../assets/web/Cartoon.jpeg");
 export const Paid = require ("../../../../assets/web/paidcrown.png");
 export const cloud11 = require("../../../../assets/web/cloud copy 11.png");
 export const question = require("../../../../assets/web/question.png");
 export const Delete = require("../../../../assets/web/Delete.png");
 export const sad = require("../../../../assets/web/sad.png");
 export const add = require("../../../../assets/web/Add.png");
 export const Uploadsmall = require("../../../../assets/web/Upload small.png");
 export const BitmapCopy = require("../../../../assets/web/Bitmap Copy 2.png");
 export const upload = require("../../../../assets/web/upload.png");
 export const BitmapCopy2 = require("../../../../assets/web/Bitmap Copy.png");
 export const camera = require("../../../../assets/web/Camera.png");
 export const bamLogo = require("../../../../assets/web/BAM_logo.png");
 export const cloudBg = require("../../../../assets/web/cloud.png");
 export const imageBitmap = require("../../../../assets/web/image_Bitmap.png");
 export const image_Group5Copy2 = require("../../../../assets/web/image_Group5Copy2.png");
 export const VideoBg = require("../../../../assets/web/Video.png");
 export const play = require("../../../../assets/web/Play_icon.png");
 export const Filter = require("../../../../assets/web/Filter.png");
 export const search = require("../../../../assets/web/Dearch.png");
 export const HelpFeedback= require("../../../../assets/web/Help & Feedback.png");
 export const Trending = require("../../../../assets/web/Trending.png");
 export const setting = require("../../../../assets/web/Settings.png");
 export const TermsConditions =require("../../../../assets/web/Terms and Conditions.png");
 export const Bitmap = require("../../../../assets/web/Bitmap.png");
 export const NotificationIcon = require("../../../../assets/web/Notification.png");
 export const Bitmap2 = require("../../../../assets/web/Bitmap 2.png");
 export const Bitmap3 = require("../../../../assets/web/Bitmap 3.png");
 export const Bitmap4 = require("../../../../assets/web/Bitmap 4.png");
 export const Bitmap5 = require("../../../../assets/web/Bitmap 5.png");
 export const edit = require("../../../../assets/web/Edit.png");
 export const crown = require("../../../../assets/web/CROWN.png");
 export const Whiz = require("../../../../assets/web/Whiz tag.png");
 export const KidBadge = require("../../../../assets/web/Badge_Warm_kid.png");
 export const ManiacBadge = require("../../../../assets/web/Badge_Warm_maniac.png");
 export const ProBadge = require("../../../../assets/web/Badge_Warm_Pro.png");
 export const StarBadge = require("../../../../assets/web/Badge_Warm_Star.png");
 export const whizBadge = require("../../../../assets/web/Badge_Warm_whiz.png");
 export const cloudBlue = require ("../../../../assets/web/cloud copy 3.png");
 export const UploadUser = require ("../../../../assets/web/uploadUser.png");
 export const PlayIcon = require("../../../../assets/web/bannerPlay.png");
 export const Trackbg = require("../../../../assets/web/trackbg.png");
 export const NotificationDots = require("../assets/notification-dots.png")

// export {Discover, Featured, Logo, cloud, Downloads, profile_logo,Favorites,Help, Settings,Terms, History,kid_Group,Heart,Download,user,Streamer, Logout,Meenu,Cartoon,Paid,cloud11,question,Delete,sad,add,Uploadsmall,BitmapCopy,upload,BitmapCopy2,camera,bamLogo,cloudBg,imageBitmap,image_Group5Copy2,VideoBg,play,Filter,search,HelpFeedback,Trending,setting,TermsConditions,Bitmap,Notification,Bitmap2,Bitmap3,Bitmap4,Bitmap5,edit,crown,Whiz,KidBadge,ManiacBadge,ProBadge,StarBadge,whizBadge,cloudBlue,UploadUser,PlayIcon,Trackbg }



